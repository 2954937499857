import {
  CardPresentation,
  Card as CardType,
  Label,
  ListStyle,
} from '@yleisradio/areena-types';
import Chevron from 'assets/chevronRight.svg';
import classNames from 'classnames';
import { GradientOverlay } from 'components/Card/GradientOverlay';
import { usePlayerActionForPointer } from 'components/Controls/Control/actions/usePlayerAction';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Link } from 'components/Link';
import { useRegionalChannelCookie } from 'hooks/useRegionalChannelCookie';
import dynamic from 'next/dynamic';
import React from 'react';
import { findLabel, getFormattedLabels } from 'utils/card';
import { isPlayerPointer } from 'utils/pointer';
import { sanitizeHtmlId } from 'utils/ui-helpers';
import styles from './Card.module.scss';
import { CardImage } from './CardImage';
import { CardLabels } from './CardLabels';
import Description from './Description';
import { ImageOverlay } from './ImageOverlay';
import { OverlayLogoImage } from './OverlayLogoImage';
import { PlayButton } from './PlayButton';
import { RemoveButton } from './RemoveButton';

const CardMenu = dynamic(() => import('./CardMenu'), { ssr: false });
const ProgressBar = dynamic(() => import('./ProgressBar'));

function isInVerticalList(listStyle: ListStyle | undefined): boolean {
  return !!listStyle && listStyle.layout === 'vertical';
}

interface CardProps {
  card: CardType;
  listStyle?: ListStyle | undefined;
  cardKey: string;
  sizes: string;
  historyLabels: Label[];
  isActive?: boolean;
  hideCard?: () => void;
  isDescriptionVisible?: boolean;
  hasEpisodeTitle?: boolean;
}

const getAspectRatioClassName = (listStyle?: ListStyle | undefined) => {
  switch (listStyle?.image) {
    case '1:1':
      return styles.withAspectRatio1_1;
    case '16:9':
    default:
      return styles.withAspectRatio16_9;
  }
};

const getTitleLinkClassName = (listStyle: ListStyle | undefined) => {
  switch (listStyle?.image) {
    case '1:1':
      return styles.audioTitleLink;
    case '16:9':
    default:
      return styles.titleLink;
  }
};

const getPresentationClassName = (
  presentation: CardPresentation | undefined
) => {
  switch (presentation) {
    case 'packageCard':
      return styles.packageCard;
    default:
      return null;
  }
};

const useSelectedCard = (cardOrDeck: CardType): CardType => {
  const [selectedRegionalChannelId] = useRegionalChannelCookie(cardOrDeck);

  if (cardOrDeck.presentation === 'broadcastDeck' && cardOrDeck.cards) {
    const selectedRegionalChannelCard = cardOrDeck.cards.find(
      (card) => card.persistentState?.id === selectedRegionalChannelId
    );

    return selectedRegionalChannelCard || cardOrDeck.cards[0] || cardOrDeck;
  }

  return cardOrDeck;
};

export const Card: React.FunctionComponent<CardProps> = ({
  card: cardOrDeck,
  listStyle,
  cardKey,
  sizes,
  historyLabels,
  isActive,
  hideCard,
  isDescriptionVisible = true,
  hasEpisodeTitle = false,
}) => {
  const card = useSelectedCard(cardOrDeck);

  const { controls = [], pointer } = card;

  const { runAction: startPlayer } =
    usePlayerActionForPointer({ pointer }) || {};
  const onClick = isPlayerPointer(pointer) ? startPlayer : undefined;

  const isVertical = isInVerticalList(listStyle);

  const formattedLabels = getFormattedLabels(card.labels);
  const overlayLabel = findLabel(formattedLabels, 'overlay');
  const highlightLabels = formattedLabels.filter((l) => l.type === 'highlight');
  const progressLabel = findLabel(card.labels, 'progress');
  const isBroadcastCard = card.presentation === 'broadcastCard';
  const genericLabels = formattedLabels.filter((l) => l.type === 'generic');

  const removeControl = controls.find((c) => c.operation?.method === 'DELETE');

  const menuControls = controls.filter(
    (c) => !!c.tag && ['queue', 'downloadLink'].includes(c.tag)
  );

  const playControl = controls.find((c) => c.tag === 'play');
  const titleId = sanitizeHtmlId(cardKey + 'title');
  const watchedDurationLabel = findLabel(historyLabels, 'watchedDuration');

  const hasRoundedCorners = listStyle?.image == '1:1' && isBroadcastCard;

  return (
    <div
      className={classNames(
        styles.card,
        getPresentationClassName(card.presentation),
        isVertical && styles.cardVertical,
        isActive && styles.cardActive,
        hasEpisodeTitle && styles.hasEpisodeTitle
      )}
      aria-labelledby={titleId}
      role="group"
      data-testid="card"
    >
      {listStyle?.image !== 'none' ? (
        <div
          className={classNames(
            styles.imageContentWrapper,
            getAspectRatioClassName(listStyle),
            hasRoundedCorners && styles.roundedCorners
          )}
        >
          <CardImage
            image={card.image}
            imageStyle={listStyle?.image}
            sizes={sizes}
            cardPresentation={card.presentation}
          />
          {card.overlayLogoImage && <GradientOverlay />}
          {card.overlayLogoImage && (
            <OverlayLogoImage
              logoImage={card.overlayLogoImage}
              listStyle={listStyle}
              altText={card.image?.alt}
            />
          )}
          {overlayLabel && <ImageOverlay overlay={overlayLabel} />}
          {highlightLabels.length > 0 && !isVertical ? (
            <div className={styles.highlightWrapper}>
              <CardLabels isHighlight labels={highlightLabels} />
            </div>
          ) : null}
          {progressLabel ? (
            <div className={styles.progressBarWrapper}>
              <ErrorBoundary>
                <ProgressBar
                  progressLabel={progressLabel}
                  watchedDurationLabel={watchedDurationLabel}
                />
              </ErrorBoundary>
            </div>
          ) : null}
          {playControl ? (
            <div className={styles.buttonWrapper}>
              <PlayButton control={playControl} isOverlay={isVertical} />
            </div>
          ) : null}
        </div>
      ) : null}

      <div className={styles.textContentWrapper}>
        {highlightLabels.length > 0 && isVertical ? (
          <div className={styles.verticalHighlightWrapper}>
            <CardLabels isHighlight labels={highlightLabels} />
          </div>
        ) : null}
        {genericLabels.length > 0 && isBroadcastCard ? (
          <div className={styles.broadcastCardLabels}>
            <CardLabels labels={genericLabels} />
          </div>
        ) : null}
        <h3 className={styles.title} id={titleId} data-testid="card-title">
          {card.pointer ? (
            <Link
              className={classNames(
                styles.link,
                getTitleLinkClassName(listStyle)
              )}
              onClick={onClick}
              pointer={card.pointer}
            >
              {card.title}
            </Link>
          ) : (
            card.title
          )}
        </h3>
        <Description text={card.description} isVisible={isDescriptionVisible} />
        {genericLabels.length > 0 && !isBroadcastCard ? (
          <div className={styles.labels}>
            <CardLabels labels={genericLabels} />
          </div>
        ) : null}
      </div>

      {isVertical ? (
        <>
          {menuControls.length > 0 ? (
            <div className={styles.controlWrapper}>
              <CardMenu controls={menuControls} />
            </div>
          ) : null}
          {removeControl ? (
            <div className={styles.controlWrapper}>
              <RemoveButton control={removeControl} hideCard={hideCard} />
            </div>
          ) : null}
          {menuControls.length === 0 && !removeControl ? (
            <div className={styles.arrowWrapper}>
              <Chevron aria-hidden={true} className={styles.arrowIcon} />
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
